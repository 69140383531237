/* FESTIVALS */
export const PAGE_TITLE = ["Festiwale", "Festivals"]
export const PAGE_PHOTO = ["page_photo.jpg"]

/* Index Festivals */
export const FESTIVALS_CHAPT1 = ["Kosmopolis 2020", "Kosmopolis 2020"]
export const FESTIVALS_CHAPT1_DESCR = [
  "XI Festiwal Literatury i Teatru",
  "XI Festival of Literature and Theatre",
]

export const FESTIVALS_CHAPT2 = ["10-16 maja 2021", "10-16 May 2021"]
export const FESTIVALS_CHAPT2_DESCR = [
  "12. Festiwal Literatury i Teatru",
  "12. Festival of Literature and Theatre",
]

export const FESTIVALS_CHAPT3 = [
  "Between.\u200BPomiędzy",
  "Between.\u200BPomiędzy",
]
export const FESTIVALS_CHAPT3_DESCR = [
  "Festiwal Rozproszony 2022",
  "Dispersed Festival 2022",
]

export const FESTIVALS_CHAPT4 = [
  "Between.\u200BPomiędzy",
  "Between.\u200BPomiędzy",
]
export const FESTIVALS_CHAPT4_DESCR = [
  "Festiwal Rozproszony",
  "Dispersed Festival",
]
