import React from "react"
//import { Link } from "gatsby";
import { langVersion } from "../../accessors"

import "./IndexChapters.scss"

const ChapterLink = ({ url, title, img, text, ...props }) => {
  const addBgImg = {
    backgroundImage: "url(" + img + ")",
  }

  const logoElem =
    props.logo !== undefined &&
    props.logo.map(item => (
      <img key={item.toString()} className="chapter__logo" src={item}></img>
    ))
  const chapterLogos = <div className="chapter-logos__box">{logoElem}</div>

  return (
    <article className="chapter">
      {url !== undefined && (
        <a
          className="chapter__link"
          href={`${url}`}
          title={langVersion(`Idź do ${title}`, `Go to ${title}`)}
        >
          <div className="chapter__picture" style={addBgImg}></div>
          <h2 className="chapter__title">{title}</h2>
          <p className="chapter__text">{text}</p>
          {props.logo !== undefined && chapterLogos}
          <div className="chapter__shadow"></div>
        </a>
      )}
      {props.attentionMark !== undefined && (
        <div className="chapter__attention-mark">
          <h2 className="mark">!</h2>
        </div>
      )}
    </article>
  )
}

export default ChapterLink
