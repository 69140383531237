import React from "react"
import Head from "../components/Head"

import Header from "../components/Headers/Header"
import Footer from "../components/Footers/Footer"
import "../styles/_layout.scss"
import PageHeader from "../components/PageHeaders/PageHeader"
import FestivalChapters from "../components/FestPages/FestivalChapters"

import { PAGE_TITLE } from "../content/festivals/constans"

const PageTitle = PAGE_TITLE[0]

const IndexPage = () => (
  <>
    <Head pageTitle={"Between.Pomiędzy | " + PageTitle} />
    <Header title={PageTitle} />

    <main className="main">
      <PageHeader pageTitle={PageTitle} />
      <FestivalChapters />
    </main>

    <Footer />
  </>
)

export default IndexPage
